import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  activePath = '';
  // menu: boolean = true;
  classification: boolean = false;
  summarization: boolean = false;
  extract: boolean = false;
  admin: boolean = false;
  constructor(

    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (sessionStorage.getItem('menuvalue') == "classification") {

      this.classification = true;
    }
    else if (sessionStorage.getItem('menuvalue') == "summarization") {

      this.summarization = true;
    }
    else if (sessionStorage.getItem('menuvalue') == "admin") {
      this.admin = true;
    }
    else {
      this.extract = true;
    }

    // this.router.events.subscribe((event: RouterEvent) => {
    //   this.activePath = event.url
    //   const str = this.activePath;
    //   if (str != undefined) {
    //     const splitted = str.split("/");
    //     console.log(splitted[1]);
    //     if (splitted[1] == "admin") {
    //       this.admin = true;

    //     }

    //   }

    // })
  }

  ngOnInit() {
    // let path1 = this.route.snapshot.url;
    // let path = location.origin;
    // var splitted = path.split("//");
    // var splitted1 = splitted[1].split(":");

    this.router.events.subscribe((event: RouterEvent) => {
      this.activePath = event.url
      const str = this.activePath;
      if (str != undefined) {
        const splitted = str.split("/");
        if (splitted[1] == "admin") {
          this.admin = true;
          this.classification = false;
          this.summarization = false;
          this.extract = false;
        }

      }

    })
  }

}
