import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [

  { path: '', redirectTo: '/authentication', pathMatch: 'full' },
  { path: 'authentication', component: LoginComponent },
  {
    path: 'Home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: 'file-upload',
    loadChildren: () => import('./file-upload/fileupload.module').then(m => m.FileUploadModule)
  },
  {
    path: 'extractfile',
    loadChildren: () => import('./set-path/setpath.module').then(m => m.SetPathModule)
  },
  {
    path: 'output',
    loadChildren: () => import('./process-completed/processcompleted.module').then(m => m.ProcessCompletedModule)
  },
  {
    path: 'Reports',
    loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'PreProcess',
    loadChildren: () => import('./pre-process/preprocess.module').then(m => m.PreProcessModule)
  },
  {
    path: 'userlogs',
    loadChildren: () => import('./user-logs/userlog.module').then(m => m.UserLogsModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./change-password/changepassword.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'singlefile/DocumentQuality',
    loadChildren: () => import('./apply-pre-processing/applypreprocessing.module').then(m => m.ApplyPreProcessingModule)
  },
  {
    path: 'batchfile/DocumentQuality',
    loadChildren: () => import('./document-quality/documentquality.module').then(m => m.DocumentQualityModule)
  },
  {
    path: 's3fileextract',
    loadChildren: () => import('./s3-fileupload/s3fileupload.module').then(m => m.S3FileuploadModule)
  },
  {
    path: 'classification',
    loadChildren: () => import('./classification-dashboard/classification.module').then(m => m.ClassificationDashboardModule)
  },
  {
    path: 'summarization',
    loadChildren: () => import('./summarization-dashboard/summarization.module').then(m => m.SummarizationDashboardModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  { path: 'resetpassword', component: ForgotPasswordComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  // { path: 'admin', component: DashboardComponent },
  // { path: 'user', component: UserCreateComponent },
  {
    path: 'Redact',
    loadChildren: () => import('./redact-file-upload/redact-file-upload.module').then(m => m.RedactFileUploadModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
