import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { RepositryService } from '../repositry.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(config: NgbDropdownConfig,
    private route: ActivatedRoute,
    private router: Router,
    private service: RepositryService,
  ) {
    config.placement = 'bottom-right';
  }
  user_name: any;
  id: any;
  menu: boolean = true;
  dashmenu:boolean=false;
  ngOnInit() {
    this.user_name = sessionStorage.getItem("name");
    let path = window.location.href;
    const splitted = path.split("/");
    const splitted1 = splitted[3].split("?");
    if (splitted1[0] != "resetpassword") {
      if (this.user_name == null) {
        this.router.navigate(["/authentication"]);
      }
    }
    if (sessionStorage.getItem('menuvalue') == "summarization") {
      this.menu = false;
    }
    if (sessionStorage.getItem("role")=="admin") {
      this.dashmenu=true;
    }
   
  }
  logout() {
    //logoutData
    this.id = sessionStorage.getItem('logid');
    this.service.logoutData(this.id).subscribe(data => {
      sessionStorage.removeItem('idtoken');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem("option");
      sessionStorage.removeItem("outputlocation");
      sessionStorage.removeItem("output_code");
      sessionStorage.removeItem('username');
      sessionStorage.removeItem("type");

      this.router.navigate(["/authentication"]);
    });

  }


}
