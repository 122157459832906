import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from "@angular/common/http";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tex.ai';
  activePath = '';
  forgot: boolean = false;
  home: boolean = false;
  summarization:boolean=false;
  constructor(
    public router: Router,
    public route: ActivatedRoute,

  ) {

    this.router.events.subscribe((event: RouterEvent) => {
      this.activePath = event.url
      const str = this.activePath;
      if (str != undefined) {
        const splitted = str.split("/");
        const splitted1 = splitted[1].split("?");
        if (splitted1[0] == "resetpassword") {
          this.forgot = true;

        }
        else if (splitted[1] == "Home") {
          this.home = true;
        }
        else if (splitted[1] == "summarization") {
          this.summarization = true;
        }
        else if (splitted[1] == "admin") {
          this.summarization = false;
          sessionStorage.removeItem('menuvalue');
          sessionStorage.setItem('menuvalue',"admin")
         
        }
        else if (splitted[1] == "admin") {
          this.summarization = false;
          sessionStorage.removeItem('menuvalue');
          sessionStorage.setItem('menuvalue',"admin")
         
        }
        else{
          this.summarization = false;
        }
      }

    })


  }
  ngOnInit() {

  }
}
