import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


// import { SERVER_API_URL } from 'app/app.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public http: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token =sessionStorage.getItem('idtoken');
        if (token) {
            request = request.clone({
                setHeaders: {
                    
                    Authorization:"Bearer "+ token
                }
            });
        }

        return next.handle(request)
        // .catch((error: any) =>{
        //     let apikey=environment.firebase.apiKey;
        //     let granttype ='refresh_token';
        //     let headers=new HttpHeaders();
        //     headers=headers.append('Content-Type','application/x-www-form-urlencoded');

        //     const url = 'https://securetoken.googleapis.com/v1/token?key='+apikey;
        //     if (error.error.status == 401) {
        //       if(error.error.res == "ID token is expired" || error.error.res == "ID token is invalid"){



        //     const body = new HttpParams()
        //       .set('grant_type', 'refresh_token')
        //       .set('refresh_token',sessionStorage.getItem('refreshtoken'));
        //       return this.http.post(url, body,{headers:headers}).flatMap(

        //         (data: any) => {

        //           //If reload successful update tokens
        //         //   if (data.status == 200) {
        //             console.log('data',data);
        //             //Update tokens
        //             sessionStorage.setItem('idtoken', data.id_token);
        //             sessionStorage.setItem('refreshtoken', data.refresh_token);
        //             //Clone our fieled request ant try to resend it
        //             request = request.clone({
        //               setHeaders: {
        //                 Authorization: data.id_token
        //               }
        //             });
        //         // }
        //         return next.handle(request);

        // });
        //     }
        //   }

        //     return Observable.throw(error);
        //     });
    }
}