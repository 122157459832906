import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RepositryService } from '../repositry.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('signinform', { static: false }) signinform: any;
  @ViewChild('forgotform', { static: false }) forgotform: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: RepositryService,

  ) { }
  signin: boolean = true;
  signup: boolean = false;
  forgot: boolean = false;
  pswvalidate: boolean = false;
  text: any;
  errorArray: any;
  errorArray1: any = [];
  message: boolean;
  signinData = { email: "", password: "", };
  forgotData = { email: "" };


  ngOnInit() {


  }
  forgotfn() {
    this.forgot = true;
    this.signup = false;
    this.signin = false
    this.forgotData.email = "";
  }

  back() {
    this.signup = false;
    this.signin = true;
    this.forgot = false;
  }
  messagefn() {
    this.message = false;
    this.signinData.email = "";
    this.signinData.password = "";
  }
  passwordclk() {
    this.pswvalidate = false;
  }
  loader: boolean = false;
  loginClick() {
    // this.router.navigate(["/forms"]);
    if (this.signinform.valid) {
      this.loader = true;
      this.service.signIn(this.signinData).subscribe(data => {
        // alert("Success");
        this.loader = false;
        sessionStorage.removeItem('menuvalue');
        sessionStorage.removeItem('idtoken');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('role');
        let accesstoken = data.result.token;
        sessionStorage.setItem('idtoken', accesstoken);
        sessionStorage.setItem('username', this.signinData.email);
        sessionStorage.setItem('name', data.result.username);
        sessionStorage.setItem('logid', data.result.log_id);
        sessionStorage.setItem('role', data.result.role);
        if (data.result.role == "admin") {
          this.router.navigate(["/admin"]);
        }
        else {
          this.router.navigate(["/Home"]);
        }

      },
        error => {
          this.loader = false;
          this.errorArray1 = [];
          this.errorArray = error.error;
          this.text = error.error.message[0];
          this.errorArray1.push(this.text);
          this.message = true;
        }
      );

    }
    else {
      Object.keys(this.signinform.form.controls).forEach(key => {
        this.signinform.form.get(key).markAsDirty();
      });
    }
  }

  forgotsubmit() {
    if (this.forgotform.valid) {
      this.loader = true;
      this.service.forgotpassword(this.forgotData).subscribe(data => {
        if (data.statuscode != 400) {
          this.loader = false;
          this.errorArray1 = [];
          this.text = "Reset password instructions have been sent to your registered email";
          this.errorArray1.push(this.text);
          this.message = true;
          this.forgotData.email = "";
          Object.keys(this.forgotform.form.controls).forEach(key => {
            this.forgotform.form.get(key).setErrors(null)
            this.forgotform.form.get(key).markAsPristine();
            this.forgotform.form.get(key).markAsUntouched();
            this.forgotform.form.get(key).updateValueAndValidity();
          });
        }
        else {
          this.loader = false;
          this.errorArray1 = [];
          this.text = data.message;
          this.errorArray1.push(this.text);
          this.message = true;
        }
      },
        error => {
          this.text = "";
          this.loader = false;
          this.errorArray1 = [];
          this.text = this.text = error.error.message;
          this.errorArray1.push(this.text);
          this.message = true;
        });
    }
    else {

      Object.keys(this.forgotform.form.controls).forEach(key => {
        this.forgotform.form.get(key).markAsDirty();
      });
    }
  }

}
