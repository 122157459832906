import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, Observer, throwError, of } from 'rxjs';
import { map, tap, catchError, finalize } from 'rxjs/operators';



import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RepositryService {
  apiBaseUrl: string;
  constructor(private router: Router, public http: HttpClient) { }
  signIn(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "user/"
    return this.http.post(path, requestdata)
  }
  signUp(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "users/"
    return this.http.post(path, requestdata)
  }
  //document extract api start
  fileUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "algorithm/"
    return this.http.post(path, requestdata)
  }
  //document extract api end
  //batch file upload start
  batchUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "batch_process/"
    return this.http.post(path, requestdata)
  }
  //batch file upload  end

  //output/?options=PdfPlumber&inputtype=local
  //option&outputlocation&output_formate&output_code;
  download(id: any, id1: any, id2: any, id3: any): Observable<any> {
    let path = environment.apiEndPoint + "output/?option=" + id + "&outputlocation=" + id1 + "&output_formate=" + id2 + "&output_code=" + id3
    return this.http.get(path, { responseType: 'arraybuffer' });
  }

  viewFile(output_code: any, outputlocation: any, username: any, type: any, output_formate: any, view_result: any, option: any): Observable<any> {
    const path = environment.apiEndPoint + "output/?output_code=" + output_code + "&outputlocation=" + outputlocation + "&username=" + username + "&type=" + type + "&output_formate=" + output_formate + "&view_result=" + view_result + "&option=" + option
    return this.http.get(path);
  }
  //report start
  getReport(): Observable<any> {
    let path = environment.apiEndPoint + "report_index/"
    return this.http.get(path);
  }
  //report end
  //forgotpasswordstart
  forgotpassword(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "forgotpassword_mail/"
    return this.http.post(path, requestdata)
  }

  //forgotpassword end

  getReportrange(id: any, id1: any): Observable<any> {
    let path = environment.apiEndPoint + "report_index/?start_date=" + id + "&end_date=" + id1
    return this.http.get(path);
  }
  //update password start
  updatepassword(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "forgotpassword_update/"
    return this.http.put(path, requestdata)
  }
  //update password end

  //reset password function start
  resetData(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "reset_password/"
    return this.http.put(path, requestdata)
  }
  //reset password function end
  //user log start
  userlogReport(): Observable<any> {
    let path = environment.apiEndPoint + "logs/"
    return this.http.get(path);
  }
  //userlog end
  //log out start
  logoutData(id: any): Observable<any> {
    let path = environment.apiEndPoint + "update_log/?id=" + id
    return this.http.put(path, '')
  }
  //log out end
  //report edit start
  reportgetByid(id: any): Observable<any> {
    let path = environment.apiEndPoint + "get_reportbyid/?id=" + id
    return this.http.get(path)
  }
  fileUpdate(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "save/"
    return this.http.post(path, requestdata)
  }
  //report edit end
  //s3 put post get api start
  getaws(): Observable<any> {
    let path = environment.apiEndPoint + "aws/"
    return this.http.get(path);
  }
  insertaws(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "aws/"
    return this.http.post(path, requestdata);
  }
  updatetaws(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "aws/"
    return this.http.put(path, requestdata);
  }
  //s3 put post get api end
  //pre-process start
  postpredata(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "preprocess/"
    return this.http.post(path, requestdata);
  }
  batchpredata(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "preprocess_batch/"
    return this.http.post(path, requestdata);
  }

  viewpredata(id: any, id1: any, id2: any): Observable<any> {
    let path = environment.apiEndPoint + "preprocess_download/?output_code=" + id + "&view_result=" + id1 + "&username=" + id2
    return this.http.get(path);
  }
  cassifyFileUpload(file: any): Observable<any> {

    let path = environment.apiEndPoint + "classification/"
    return this.http.post(path, file);
  }
  predictFile(data) {
    let path = environment.apiEndPoint + "predict_text/"
    return this.http.post(path, data);
  }
  //pre -process end
  //document quality start
  documentQuality(file: any): Observable<any> {
    let path = environment.apiEndPoint + "get_document_quality/"
    return this.http.post(path, file);
  }
  //document quality end
  //document quality start for batch
  batchdocumentQuality(file: any): Observable<any> {
    let path = environment.apiEndPoint + "get_document_quality_for_batch/"
    return this.http.post(path, file);
  }
  //document quality end for batch
  //classification report api start
  cassifyReport(): Observable<any> {
    let path = environment.apiEndPoint + "get_classification_reports/"
    return this.http.get(path);
  }

  //classification report api end
  clasifyReportrange(id: any, id1: any): Observable<any> {
    let path = environment.apiEndPoint + "get_classification_reports/?start_date=" + id + "&end_date=" + id1
    return this.http.get(path);
  }
  //summarization start
  postdatasummarization(file: any): Observable<any> {
    let path = environment.apiEndPoint + "keyphrase_extraction_file/"
    return this.http.post(path, file);
  }

  summarizationtext(data) {
    let path = environment.apiEndPoint + "keyphrase_extraction/"
    return this.http.post(path, data);
  }
  docvalidation(data) {
    let path = environment.apiEndPoint + "validate/"
    return this.http.post(path, data);
  }
  //summarization end
  //dashboard start
  getdashboardcount(): Observable<any> {
    let path = environment.apiEndPoint + "count"
    return this.http.get(path);
  }
  //dashboard end
  //user report start
  getuserReport(): Observable<any> {
    let path = environment.apiEndPoint + "user_detail/"
    return this.http.get(path);
  }
  //user report end
  //user report start
  singleuser(id): Observable<any> {
    let path = environment.apiEndPoint + "delete_update_user/" + id
    return this.http.get(path);
  }
  //user report end
  //admin update api start
  update(id, requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "delete_update_user/" + id
    return this.http.put(path, requestdata)
  }
  //admin update api end
  //admin dashboard report
  dashExtrReport(): Observable<any> {
    let path = environment.apiEndPoint + "get_report_data/"
    return this.http.get(path);
  }
  dashClasfyReport(): Observable<any> {
    let path = environment.apiEndPoint + "get_classification_metrics/"
    return this.http.get(path);
  }
  dashSumReport(): Observable<any> {
    let path = environment.apiEndPoint + "get_summarization_metrics/"
    return this.http.get(path);
  }
  // highlighted text start_
  highlighted(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "get_highlighted_data/"
    return this.http.post(path, requestdata)
  }
  // highlighted text end

  redactfileUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "redaction/"
    return this.http.post(path, requestdata)
  }
  redactfolderUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "redaction_batch/"
    return this.http.post(path, requestdata)
  }
  redactresult(id: any, id1: any, id2: any): Observable<any> {
    let path = environment.apiEndPoint + "get_redaction_file/?output_code=" + id + "&username=" + id1 + "&view_result=" + id2
    return this.http.get(path);
  }
  clinicalfileUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "redact_healthcare_documents/"
    return this.http.post(path, requestdata)
  }
  clinicalfolderUpload(requestdata: any): Observable<any> {
    let path = environment.apiEndPoint + "redact_batch_healthcare/"
    return this.http.post(path, requestdata)
  }
}
