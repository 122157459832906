import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';
import { RepositryService } from '../repositry.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('signinform', { static: false }) signinform: any;
  activePath = "";
  constructor(

    private route: ActivatedRoute,
    private router: Router,
    private service: RepositryService,
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.activePath = event.url
      const str = this.activePath;
      if (str != undefined) {
        sessionStorage.removeItem("oldpassword");
        const splitted = str.split("/");
        const splitted1 =  splitted[1].split("=");
        sessionStorage.setItem("oldpassword", splitted1[1])

      }

    })
  }
  text: any;
  errorArray: any;
  errorArray1: any = [];
  message: boolean;

  signinData = { new_password: "", confirm_password: "", old_password: "" };
  ngOnInit() {
    this.signinData.old_password = "";
    this.signinData.old_password = sessionStorage.getItem("oldpassword");
  }

  loginClick() {
    if (this.signinform.valid) {
      if (this.signinData.new_password.length > 7) {
        this.service.updatepassword(this.signinData).subscribe(data => {
          alert("Password Updated");
          window.location.href = "/authentication";
        });
      }
      else {
        this.errorArray1 = [];
        var text = " Password must contain more than 8 characters"
        this.errorArray1.push(text);
        this.message = true;

      }

    }
    else {
      Object.keys(this.signinform.form.controls).forEach(key => {
        this.signinform.form.get(key).markAsDirty();
      });
    }
  }
  // comparefn() {
  //   if (this.signinData.new_password == this.signinData.confirm_password) {
  //     return true;
  //   }
  //   else {
  //     var text = "New password & Confirm password doesn't match"
  //     this.errorArray1.push(text);
  //     this.message = true;

  //   }
  // }
  okfunction() {
    this.message = false;
    this.signinData.new_password = "";

  }

}
